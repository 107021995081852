import React, { useState } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { releases } from "../data";
import { useStaticQuery, Link, graphql } from "gatsby";

import ReleaseLinks from "../components/release-links";

const coversQuery = graphql`
  query {
    nice: file(relativePath: { eq: "nice.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    atHome: file(relativePath: { eq: "at-home.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    boesmanBlues: file(relativePath: { eq: "boesman-blues.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    trioStyle: file(relativePath: { eq: "volume 2 - trio style.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    imIn: file(relativePath: { eq: "I'm in cover square.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    stepByStep: file(relativePath: { eq: "step by step big.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    lawnmowerEp: file(relativePath: { eq: "lawnmower ep.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    home: file(relativePath: { eq: "home.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ruse: file(relativePath: { eq: "ruse.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    pretext: file(relativePath: { eq: "pretext.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mssapessm: file(relativePath: { eq: "Mssapessm.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    beatTapes: file(relativePath: { eq: "beat tapes.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fragments: file(relativePath: { eq: "fragments.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    hechoEnMexico: file(relativePath: { eq: "hecho en mexico.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    anecdotes: file(relativePath: { eq: "anecdotes.jpeg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    characters: file(relativePath: { eq: "characters.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jetLag: file(relativePath: { eq: "jet lag.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ohb: file(relativePath: { eq: "ohb.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    promisedLand: file(relativePath: { eq: "promised land ep.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    theBeautifulMadness: file(
      relativePath: { eq: "the beautiful madness.jpg" }
    ) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    justActNormal: file(relativePath: { eq: "just_act_normal.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    darkHalo: file(relativePath: { eq: "dark halo.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    songForMyFutureWife: file(
      relativePath: { eq: "song_for_my_future_wife.jpg" }
    ) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    whatAmIDoing: file(relativePath: { eq: "what am i doing.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const sortByDate = (a, b) => {
  const aToUse = a.recorded ?? a.released;
  const bToUse = b.recorded ?? b.released;
  const aDate = new Date(aToUse.year, aToUse.month - 1, aToUse.day);
  const bDate = new Date(bToUse.year, bToUse.month - 1, bToUse.day);

  return aDate < bDate ? 1 : -1;
};

const sortByNone = (a, b) => 0;

const sortByTitle = (a, b) =>
  a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;

const sortByArtist = (a, b) =>
  a.artist.toLowerCase() < b.artist.toLowerCase() ? -1 : 1;

const Music = () => {
  const data = useStaticQuery(coversQuery);

  const [sortBy, setSortBy] = useState("custom");

  const sortFn =
    sortBy === "date"
      ? sortByDate
      : sortBy === "title"
      ? sortByTitle
      : sortBy === "artist"
      ? sortByArtist
      : sortByNone;

  return (
    <Layout>
      <SEO title="Music" />
      <h1>Music</h1>
      <div
        css={css`
          margin-bottom: 16px;
        `}
      >
        I perform, record, produce and mix. Below you'll find some of the
        projects I've worked on, as well as links to listen.
      </div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          margin-bottom: 16px;
        `}
      >
        <label>
          Sort by{" "}
          <select
            value={sortBy}
            onChange={e => {
              setSortBy(e.target.value);
            }}
          >
            <option value="custom">Custom</option>
            <option value="date">Date (newest first)</option>
            <option value="title">Title</option>
            <option value="artist">Artist</option>
          </select>
        </label>
      </div>
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          gridGap: "8px",
          gridRowGap: "32px",
        }}
      >
        {[...releases].sort(sortFn).map((r, idx) => {
          return (
            <div key={idx}>
              <Link to={`/music/${r.slug}/`}>
                <Img
                  css={css`
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                    width: 450px;
                    max-width: calc(100vw - 2.175rem);
                  `}
                  fixed={data[r.imageKey].childImageSharp.fixed}
                />
                <div
                  css={css`
                    text-decoration: none;
                    color: black;
                    /* font-size: ${16}px; */
                    font-family: -apple-system, BlinkMacSystemFont, Segoe UI,
                      Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
                      Helvetica Neue, sans-serif;
                  `}
                >{`${r.artist} • ${r.title}`}</div>
              </Link>
              <ReleaseLinks release={r} iconSize={16 + 8} />
            </div>
          );
        })}
      </div>
      {/* <Link to="/">Go back to the homepage</Link> */}
    </Layout>
  );
};

export default Music;
