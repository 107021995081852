export type DateType = { year: number; month: number; day: number };

export type Release = {
  slug: string;
  imageKey: string;
  title: string;
  artist: string;
  credits: string;
  released: DateType;
  recorded?: DateType;
  description?: string;
  links?: {
    appleMusic?: string;
    spotify?: string;
    bandcamp?: string;
    amazonMusic?: string;
    youtubeMusic?: string;
    soundcloud?: string;
    youtube?: string;
    deezer?: string;
    download?: string;
  };
};

export const releases: Release[] = [
  {
    slug: "im-in",
    imageKey: "imIn",
    title: "I'm In",
    artist: "Chilled Vibes",
    credits:
      "Writing, guitar, bass, tracking and mixing with Fábio da Graça and Asher Gamedze.",
    released: { year: 2013, month: 10, day: 9 },
    links: {
      soundcloud: "https://soundcloud.com/chilled-vibes/sets/im-in",
      bandcamp: "https://music.chilledvibes.com/album/im-in",
      spotify: "https://open.spotify.com/album/7g1v9wC1VKvDI3YkPgQV3D",
      amazonMusic: "https://music.amazon.com/albums/B07FZLMSZ3",
      appleMusic: "https://music.apple.com/th/album/im-in-ep/1418860909",
      deezer: "https://www.deezer.com/album/69324972",
      // youtube:
      //   "https://www.youtube.com/watch?v=MX4JelAOzlo&list=OLAK5uy_kP7GXvTjZBqw2oytZXkvDE7oVjADkll2A",
      youtubeMusic:
        "https://music.youtube.com/playlist?list=OLAK5uy_kQsIjQboEcFIOBsK2Qcs71TkrdBHvHE8U",
    },
  },
  {
    slug: "at-home",
    imageKey: "atHome",
    title: "At Home",
    artist: "Robert Scott",
    credits: "Compositions and electric guitar.",
    description:
      "Live and direct from a spot in Observatory Cape Town where I was living at the time.<br/><br/>Asher Gamedze - Drums, Clem Carr - Keys, Eugene Ackerman - Bass.",
    released: { year: 2014, month: 12, day: 12 },
    links: {
      soundcloud: "https://soundcloud.com/robsco/sets/robert-scott-at-home",
    },
  },
  {
    slug: "step-by-step",
    imageKey: "stepByStep",
    title: "Step By Step",
    artist: "Chilled Vibes",
    credits: "Co-writing through to master with Fábio da Graça.",
    released: { year: 2012, month: 8, day: 23 },
    links: {
      soundcloud: "https://soundcloud.com/chilled-vibes/sets/step-by-step",
      bandcamp: "https://music.chilledvibes.com/album/step-by-step",
      spotify: "https://open.spotify.com/album/1AbHYCRNqSkKdQnRQLP09j",
      amazonMusic: "https://music.amazon.com/albums/B07FZLR77X",
      appleMusic: "https://music.apple.com/th/album/step-by-step-ep/1418855075",
      deezer: "https://www.deezer.com/en/album/69257292",
      youtubeMusic:
        "https://music.youtube.com/playlist?list=OLAK5uy_k9JCMXN76O-MXTCpyLrKJfEQ9_G5qbDpc",
    },
  },
  {
    slug: "trio-style",
    imageKey: "trioStyle",
    title: "trio style",
    artist: "volume 2",
    credits: "Bass, bass synth, electric guitar (track 15) and mixing.",
    description: `Went into studio for an improvised session with Asher Gamedze and Dustin van Wyk at <a href="https://www.facebook.com/ConceptRecordsCPT/" target="_blank" rel="noopener noreferrer">Concept Records</a> in Cape Town. This is the result.`,
    released: { year: 2019, month: 8, day: 27 },
    links: {
      appleMusic: "https://music.apple.com/za/album/trio-style/1479197193",
      spotify: "https://open.spotify.com/album/6SuIPeMpoogyVkSxaJyPCW",
      bandcamp: "https://volume2.bandcamp.com/album/trio-style",
      amazonMusic: "https://music.amazon.com/albums/B07XHNW3P8",
      youtubeMusic:
        "https://music.youtube.com/playlist?list=OLAK5uy_kuV8Bm1fMt5ZjkcN-pQCv30RD7vbpVrUw",
    },
  },
  {
    slug: "home",
    imageKey: "home",
    title: "Home",
    artist: "Chris Cape",
    released: { year: 2017, month: 5, day: 31 },
    credits: "Co-produced, written and recorded with Chris Cape.",
    links: {
      spotify: "https://open.spotify.com/album/0NbNVLWZdClpQApnodvfDT",
      appleMusic:
        "https://music.apple.com/gb/album/home-feat-robsco-single/1357717557",
      bandcamp: "https://chriscape.bandcamp.com/track/home-feat-robsco-2",
      youtubeMusic: "https://music.youtube.com/watch?v=UyN8BXk38gE",
    },
  },

  {
    slug: "fragments",
    imageKey: "fragments",
    title: "fragments",
    artist: "robsco",
    credits: "Everything.",
    released: { year: 2015, month: 6, day: 17 },
    description:
      "To me 'fragments' are different elements, moments and pursuits in ones life. Sometimes - when combined - the fragments can become overwhelming. Other times they happen to shift perfectly into place.",
    links: {
      soundcloud: "https://soundcloud.com/robsco/sets/fragments",
      download: "robsco - fragments [mp3].zip",
    },
  },
  {
    slug: "ruse",
    imageKey: "ruse",
    title: "Ruse",
    artist: "Motheo Moleko",
    credits: "Electric guitar.",
    released: { year: 2015, month: 11, day: 11 },
    links: {
      soundcloud: "https://soundcloud.com/motheomoleko/ruse",
      spotify: "https://open.spotify.com/track/3ANjhtLMAralPP0vse1hsG",
      appleMusic: "https://music.apple.com/us/album/ruse-single/1063350028",
      youtubeMusic: "https://music.youtube.com/watch?v=Q1TbzHWFkuQ",
    },
  },
  {
    slug: "hecho-en-mexico",
    imageKey: "hechoEnMexico",
    title: "Hecho en México",
    artist: "robsco",
    credits: "Everything",
    released: { year: 2018, month: 11, day: 29 },
    links: { soundcloud: "https://soundcloud.com/robsco/sets/hecho-en-mexico" },
  },
  {
    slug: "lawnmower",
    imageKey: "lawnmowerEp",
    title: "The Lawnmower EP",
    artist: "Chris Cape",
    credits: "Co-produced, written and recorded with Chris Cape.",
    released: { year: 2016, month: 8, day: 30 },
    links: {
      spotify: "https://open.spotify.com/album/1F3IflkUBWRYNqMdZc1YDf",
      bandcamp: "https://chriscape.bandcamp.com/album/the-lawnmower-ep",
      appleMusic:
        "https://music.apple.com/za/album/the-lawnmower-ep/id1211696509",
      youtubeMusic:
        "https://music.youtube.com/playlist?list=OLAK5uy_lIgqFtUX1Ru4wxfFrm_OO_04AR6b2MOYs",
    },
  },
  {
    slug: "nice",
    imageKey: "nice",
    title: "Nice",
    artist: "Luh'ra",
    credits: "Electric guitar.",
    released: { year: 2022, month: 7, day: 22 },
    links: {
      spotify: "https://open.spotify.com/album/7m5ZWrFd3dbAW6oOrkxdm6",
      youtubeMusic:
        "https://music.youtube.com/playlist?list=OLAK5uy_kjTrvReqqpg6omw8UBtvVeB6wkJ9bAOA8",
      appleMusic: "https://music.apple.com/za/album/nice/1634407293",
      amazonMusic: "https://www.amazon.com/Nice/dp/B0B6GFVCRM",
    },
  },
  {
    slug: "beat-tapes-vol-1",
    imageKey: "beatTapes",
    title: "Beat Tape Vol. 1",
    artist: "The Beat Tapes",
    released: { year: 2015, month: 8, day: 31 },
    credits:
      "Co-produced with One Stab (Asher Gamedze). Instrumentation, tracking, mixing and mastering.",
    links: {
      bandcamp:
        "https://primitiveancestorrecords.bandcamp.com/album/beat-tape-vol-1",
    },
  },
  {
    slug: "what-am-i-doing",
    imageKey: "whatAmIDoing",
    title: "What Am I Doing",
    artist: "Chilled Vibes",
    released: { year: 2018, month: 10, day: 3 },
    credits: "Bass, electric guitar, vocals, keyboards, production and mixing.",
    links: {
      soundcloud: "https://soundcloud.com/chilled-vibes/what-am-i-doing",
    },
  },
  {
    slug: "pretext",
    imageKey: "pretext",
    title: "Pretext",
    artist: "Momentss",
    credits: "Guitar. Tracking engineer.",
    released: { year: 2014, month: 11, day: 3 },
    links: {
      spotify: "https://open.spotify.com/album/5LZoptRamBHMdbfjOUamrm",
      appleMusic: "https://music.apple.com/ag/album/pretext-ep/939614560",
      youtube:
        "https://www.youtube.com/watch?v=kg18DPuwiqE&list=OLAK5uy_n0rXt1TYMCQ8iwGXgIgtHCBz0AcFF-YtY",
      youtubeMusic:
        "https://music.youtube.com/playlist?list=OLAK5uy_ldsuY69omke1ARsYONezTlf7o6nbo0ksA",
    },
  },
  {
    slug: "ohb",
    imageKey: "ohb",
    title: "OHB",
    artist: "Obs House Band",
    credits: "Electric guitar, mix and master.",
    released: { year: 2014, month: 5, day: 24 },
    links: { download: "Obs House Band - OHB [mp3].zip" },
  },
  {
    slug: "boesman-blues",
    imageKey: "boesmanBlues",
    title: "My Boesman Blues",
    artist: "Emile YX? and Manny Walters",
    credits: "Electric bass.",
    released: { year: 2022, month: 10, day: 28 },
    recorded: { year: 2016, month: 6, day: 13 },
    description:
      'Went into studio in 2016 to record some bass for Emile YX? and Manny Walters for a TV show called "Jam Sandwich". Here\'s a clip from the show: <a href="https://www.facebook.com/watch/?v=1964003703732617">https://www.facebook.com/watch/?v=1964003703732617</a>',
    links: {
      spotify:
        "https://open.spotify.com/track/6HFeObHK0mRS6NNDKNUKQG?si=e3132f1d83c64217",
      youtubeMusic: "https://music.youtube.com/watch?v=uXoCqs6JH9Q",
    },
  },
  {
    slug: "anecdotes",
    imageKey: "anecdotes",
    title: "Anecdotes",
    artist: "Manny Walters",
    credits: "Bass, tracking and mixing.",
    released: { year: 2015, month: 6, day: 25 },
    links: {
      spotify: "https://open.spotify.com/album/2VHeJ5aRnhGwz7n3mokakB",
      youtubeMusic:
        "https://music.youtube.com/playlist?list=OLAK5uy_nr_c4li0aEvcgJY_3oIU6jJplSDDiPio4",
      appleMusic: "https://music.apple.com/za/album/anecdotes-ep/1440426775",
    },
  },
  {
    slug: "the beautiful-madness",
    imageKey: "theBeautifulMadness",
    title: "The Beautiful Madness",
    artist: "TRESOR",
    released: { year: 2017, month: 5, day: 26 },
    credits: `Electric guitar on "Tidal Wave", "Wonder", "Sweetest Fall" and "Feels".`,
    links: {
      spotify: "https://open.spotify.com/album/0iZddx9C4DCDy6CxDmjyIY",
      appleMusic:
        "https://music.apple.com/za/album/the-beautiful-madness/1444017556",
      youtubeMusic:
        "https://music.youtube.com/playlist?list=OLAK5uy_mQ8URp6GJJoWKyybNC1YpWdpqfRYl3cs0",
    },
  },
  {
    slug: "dark-halo",
    imageKey: "darkHalo",
    title: "Dark Halo",
    artist: "Manny Walters",
    credits: `Bass on "Slowing Down" and "Hear Me Now".`,
    released: { year: 2019, month: 8, day: 16 },
    links: {
      spotify: "https://open.spotify.com/album/2nNAqTNjh7T13ZPLqCZ5jV",
      appleMusic: "https://music.apple.com/us/album/1475622893",
      youtubeMusic:
        "https://music.youtube.com/playlist?list=OLAK5uy_mnqe5KSXHIQgH53DcDyqTvj3B23HiOcgo",
    },
  },
  {
    slug: "promised-land",
    imageKey: "promisedLand",
    title: "Promised Land EP",
    artist: "The Green Grass Band",
    released: { year: 2012, month: 2, day: 29 },
    credits:
      "Use of bedroom for studio. Co-writing and peformance (guitar). Co-recording engineer and co-master.",
    links: {
      bandcamp: "https://greengrassband.bandcamp.com/album/promised-land-ep",
    },
  },
  {
    slug: "mssapessm",
    imageKey: "mssapessm",
    title: "Mssapessm",
    artist: "Grymttr",
    credits: "Electric guitar on tracks 8, 10 and 11.",
    released: { year: 2014, month: 7, day: 1 },
    links: { bandcamp: "https://skateworldtapes.bandcamp.com/album/mssapessm" },
  },

  {
    slug: "song-for-my-future-wife",
    imageKey: "songForMyFutureWife",
    title: "Song For My Future Wife",
    artist: "Digital Sangoma",
    credits: "Electric guitar.",
    released: { year: 2013, month: 8, day: 15 },
    links: { youtube: "https://www.youtube.com/watch?v=bE44-cJYj2s" },
  },

  {
    slug: "jet-lag",
    imageKey: "jetLag",
    title: "Jet Lag EP",
    artist: "Londi Gamedze",
    credits:
      "Performance on track 4 (guitar). Recording and co-production on tracks 4 and 6. Mastering.",
    released: { year: 2013, month: 3, day: 8 },
    links: {
      spotify: "https://open.spotify.com/album/5TbU0nTxuvUh05VxPzKDGh",
      bandcamp: "https://londi.bandcamp.com/album/jet-lag-ep",
      youtubeMusic:
        "https://music.youtube.com/playlist?list=OLAK5uy_lKHItJGc4DEvyp6C7pJl8GvjowH5Ca1VE",
    },
  },

  {
    slug: "characters",
    imageKey: "characters",
    title: "Characters",
    artist: "Rosmary Townsend",
    credits: "Recording engineer for the LP. Mixing on tracks 1,2,5,9 and 10.",
    description: "We had a frothing time packing grapes out in Betty's Bay.",
    released: { year: 2013, month: 7, day: 4 },
    links: {
      bandcamp: "https://rosemarytownsend.bandcamp.com/album/characters",
    },
  },
  {
    slug: "just-act-normal",
    imageKey: "justActNormal",
    title: "Just Act Normal",
    released: { year: 2015, month: 11, day: 26 },
    artist: "TheCITY",
    credits: "Mixing.",
    links: {
      soundcloud:
        "https://soundcloud.com/red-bull-studio-ct/thecity-just-act-normal",
    },
  },
];

type Video = { name: string; url: string };

export const videos: Video[] = [
  {
    name: "Vinnie Makeleni performs 'Crucifix'",
    url: "https://www.youtube.com/watch?v=PZ92BcuACjY",
  },
  {
    name: "Manny Walters - Martha (Popsicle Studio Session)",
    url: "https://www.youtube.com/watch?v=a_gJ10_M9II",
  },
  {
    name: "Chris Cape - Home (feat. Robsco)",
    url: "https://www.youtube.com/watch?v=aJcCupNSUT4",
  },
  {
    name: "Manny Walters - Joseph (Popsicle Studios Session)",
    url: "https://www.youtube.com/watch?v=zS4ZCC5-UYo",
  },
  {
    name: "KUDUKUDU PRESENTS: Albany Lore Swimming (Live at Mercury Live)",
    url: "https://www.youtube.com/watch?v=d540BG_vmRk",
  },
  {
    name: `Can Skylark performs "Everything Is Cool" live on Expresso`,
    url: "https://www.youtube.com/watch?v=oSUsbP8dgHc",
  },
  {
    name:
      "Tiggs Da Author - Antidote (Travis Scott Cover) [Cape Town Sessions]",
    url: "https://www.youtube.com/watch?v=f5zwj5Th9WE",
  },
  {
    name: "Love songs from Mandie Mafu",
    url: "https://www.youtube.com/watch?v=rgl4gcbNhVE",
  },

  {
    name: "A day in the studio with Chilled Vibes",
    url: "https://www.youtube.com/watch?v=oWKxT0TyINs",
  },
  {
    name: "TheCITY Master Blaster Cover",
    url: "https://www.youtube.com/watch?v=0uYRr4VrQgc",
  },
  {
    name: "The x42-eq or fil4.lv2 Equaliser Plugin",
    url: "https://www.youtube.com/watch?v=2kbHPL_9Kyw",
  },
  {
    name: "Using Meterbridge with JACK Inputs and Outputs",
    url: "https://www.youtube.com/watch?v=4R0Qzm9ZZfo",
  },
  // {
  //   name:
  //     "Manny Walters - Converse Rubber Tracks presents Get Out Of The Garage 2014",
  //   url: "https://www.youtube.com/watch?v=z10zqNPtMdo",
  // },
];
